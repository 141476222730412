import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { backgroundColor, error, primary, secondary } from './constants';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: backgroundColor
    },
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: error
    },
    text: {
      primary: primary,
      secondary: secondary
    }
  },
  typography: {
    fontFamily: "Barlow Condensed",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
