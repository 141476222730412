import { Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import AppbarComponent, { AppbarLink } from "../components/Appbar.component";
import FooterComponent from "../components/Footer.component";
import GalleryRowComponent from "../components/GalleryRow.component";
import GalleryCategory from "../models/GalleryCategory.model";
import GalleryRow from "../models/GalleryRow.model";
import GalleryCategoryService from "../services/GalleryCategory.service";
import GalleryRowService from "../services/GalleryRow.service";

function GalleryDetailPage() {

    let { categoryId } = useParams();

    const [cookie,,] = useCookies(["token"]);

    const [galleryImages, setGalleryImages] = useState<GalleryRow[]>([]);
    const [galleryCategory, setGalleryCategory] = useState<GalleryCategory | undefined>(undefined);

    const [isLoading, setIsLoading] = useState(false);

    const links: AppbarLink[] = [
        {
            id: 1,
            name: "Gallery",
            link: "/gallery",
            active: true,
        },
        {
            id: 2,
            name: "About Me",
            link: "/about",
            active: false,
        },
        {
            id: 3,
            name: "Contact Me",
            link: "/contact",
            active: false,
        },
    ];

    cookie.token && links.push({id: 8, name: "Categories", link: "/admin/category", active: false});
    cookie.token && links.push({id: 9, name: "Messages", link: "/admin/messages", active: false});

    useEffect(() => {
        setIsLoading(true);

        const rowService: GalleryRowService = new GalleryRowService();
        const categoryService: GalleryCategoryService = new GalleryCategoryService();

        categoryService.getCategory(Number(categoryId)).then(res => {
            setGalleryCategory(res);
        }).catch(err => {

        })

        rowService.getAllByCategory(Number(categoryId)).then(res => {
            setGalleryImages(res);
            setIsLoading(false);
        }).catch(err => {

        });
    }, []);

    return ( 
        <Container maxWidth="xl">
            <AppbarComponent link={links}  />

            <Grid container paddingTop={10} spacing={2}>
                <Grid item xs={12} textAlign="center">
                    { galleryCategory && <Typography fontSize={24} fontWeight="medium">{ galleryCategory?.categoryName }</Typography>}
                </Grid>
                {!isLoading &&
                    galleryImages.map(row => 
                        <Grid key={row.rowId} item xs={12}>
                            <GalleryRowComponent images={row.images!} />
                        </Grid>
                )}
            </Grid>

            <FooterComponent />
        </Container>
     );
}

export default GalleryDetailPage;