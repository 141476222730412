import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { useCookies } from "react-cookie";
import GalleryImage from "../models/GalleryImage.model";
import GalleryRowService from "../services/GalleryRow.service";

type RemoveImageDialogProps = {
    open: boolean;
    onClose: () => void;
    image?: GalleryImage;
    rowId: number;
}

function RemoveImageDialog(props: RemoveImageDialogProps) {

    const [cookie,,] = useCookies(["token"]);

    const [removeErr, setRemoveErr] = useState(false);

    const closeSnackbar = () => {
        setRemoveErr(false);
    }

    const handleRemoveImage = () => {
        const rowService: GalleryRowService = new GalleryRowService();

        rowService.deleteImage(props.rowId, props.image?.imageId!, cookie.token).then(() => {
            window.location.reload();
            props.onClose();
        }).catch(() => {
            setRemoveErr(true);
        })
    }

    return ( 
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Remove Image with ID { props.image && props.image.imageId }?</DialogTitle>
            <DialogContent>
                <Typography>Pokud zmacknes Remove tak ti to smaze fotku. To jen kdybys nechapal co ta ikonka znamena.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleRemoveImage}>Remove</Button>
                <Button variant="outlined" onClick={props.onClose}>Cancel</Button>
            </DialogActions>

            <Snackbar open={removeErr} autoHideDuration={6000} onClose={closeSnackbar} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                <Alert onClose={closeSnackbar} variant="outlined" severity="error" sx={{ width: '100%' }}>
                    Nemuzes smazat fotku, ktera je pouzita jako hlavni fotka kategorie demente.
                </Alert>
            </Snackbar>

        </Dialog>
     );
}

export default RemoveImageDialog;