import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import GalleryImage from "../models/GalleryImage.model";

type GalleryRowComponentProps = {
  images: GalleryImage[];
};

const transition = {
  type: "spring",
  damping: 25,
  stiffness: 120,
};

function GalleryRowComponent(props: GalleryRowComponentProps) {
  const [isOpen, setOpen] = useState(0);
  return (
    <Grid container spacing={1}>
      {props.images?.map((image) => {
        const [imageFile, extension] = image.imagePath.split(".");

        return (
          <Grid
            key={image.imageId}
            item
            xs={12}
            lg={12 / props.images.length}
            sx={{
              height: { xs: 225, sm: 330, md: 450, lg: 560 },
              marginBottom: { xs: 1, sm: 1, md: 1, lg: 0}
            }}
          >
            <div
              className={`image-container ${
                isOpen === image.imageId ? "open" : ""
              }`}
            >
              <motion.div
                animate={{ opacity: isOpen ? 0.8 : 0 }}
                transition={transition}
                className="shade"
                onClick={() => setOpen(0)}
                onContextMenu={(e) => {
                  if (e.button === 2) {
                    e.preventDefault();
                    return false;
                  }
                }}
              />
              <motion.img
                src={
                  isOpen === image.imageId || props.images.length === 1
                    ? `https://api.jan-hajek.com/content${image.imagePath}`
                    : `https://api.jan-hajek.com/content${imageFile}_thumbnail.${extension}`
                }
                onClick={() =>
                  isOpen === 0 ? setOpen(image.imageId) : setOpen(0)
                }
                animate={transition}
                onContextMenu={(e) => {
                  if (e.button === 2) {
                    e.preventDefault();
                    return false;
                  }
                }}
              />
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default GalleryRowComponent;
