import { Box, Divider, Grid, IconButton, Link, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
import AppbarComponent, { AppbarLink } from "../../components/Appbar.component";
import DeleteIcon from '@mui/icons-material/Delete';
import FooterComponent from "../../components/Footer.component";
import RemoveMessageDialog from "../../dialogs/RemoveMessage.dialog";
import { useEffect, useState } from "react";
import MessageModel from "../../models/Message.model";
import MessagesService from "../../services/Messages.service";
import { useCookies } from "react-cookie";

const links: AppbarLink[] = [
    {
        id: 1,
        name: "Gallery",
        link: "/gallery",
        active: false,
    },
    {
        id: 2,
        name: "About Me",
        link: "/about",
        active: false,
    },
    {
        id: 3,
        name: "Contact Me",
        link: "/contact",
        active: false,
    },
    {
        id: 8,
        name: "Categories",
        link: "/admin/category",
        active: false,
    },
    {
        id: 9,
        name: "Messages",
        link: "/admin/messages",
        active: true,
    },
];

function AdminMessagePage() {

    const [cookie,,] = useCookies(["token"]);

    const [messageDialogOpen, setMessageDialogOpen] = useState(false);

    const [chosenMessage, setChosenMessage] = useState<MessageModel | undefined>(undefined);

    const [messages, setMessages] = useState<MessageModel[]>([]);

    const handleMessageDialogOpen = (message: MessageModel) => {
        setChosenMessage(message);
        setMessageDialogOpen(true);
    }
    const handleMessageDialogClose = () => {
        setMessageDialogOpen(false);

        const messageService: MessagesService = new MessagesService();

        messageService.getMessages(cookie.token).then(res => {
            setMessages(res);
        });
    }

    useEffect(() => {
        const messageService: MessagesService = new MessagesService();

        messageService.getMessages(cookie.token).then(res => {
            setMessages(res);
        });
    },[])

    return ( 
        <Container maxWidth="lg">
            <AppbarComponent link={links} />
            <Grid container spacing={2}>
                {messages.map(message => 
                    <Grid key={message.messageId} item xs={12} lg={6}>
                        <Paper sx={{padding: 1, height: 500}}>
                            <Box textAlign="right">
                                <IconButton onClick={() => handleMessageDialogOpen(message)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                            <Typography p={1}><b>Username: </b> {message.userName}</Typography>
                            <Typography p={1}><b>Email: </b> <Link href={`mailto:${message.userEmail}`}>{message.userEmail}</Link></Typography>
                            <Divider />
                            <Typography p={1}>{message.messageText}</Typography>
                        </Paper>
                    </Grid>
                )}
            </Grid>

            <RemoveMessageDialog open={messageDialogOpen} onClose={handleMessageDialogClose} message={chosenMessage} />

            <FooterComponent />
        </Container>
     );
}

export default AdminMessagePage;