import { useCookies } from "react-cookie";
import { Route, Routes } from "react-router-dom";
import AboutPage from "./pages/About.page";
import AdminCategoryPage from "./pages/admin/AdminCategory.page";
import AdminCategoryDetailPage from "./pages/admin/AdminCategoryDetail.page";
import AdminMessagePage from "./pages/admin/AdminMessage.page";
import ContactPage from "./pages/Contact.page";
import FourOhFour from "./pages/FourOhFour";
import GalleryPage from "./pages/Gallery.page";
import GalleryDetailPage from "./pages/GalleryDetail.page";
import HomePage from "./pages/Home.page";
import LoginPage from "./pages/Login.page";

function App() {

  const [cookie,,] = useCookies(["token"]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/gallery/:categoryId" element={<GalleryDetailPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />

        <Route path="/login" element={<LoginPage />} />

        <Route path="/admin/category" element={cookie.token ? <AdminCategoryPage /> : <FourOhFour />} />
        <Route path="/admin/category/:categoryId" element={cookie.token ? <AdminCategoryDetailPage /> : <FourOhFour />} />
        <Route path="/admin/messages" element={cookie.token ? <AdminMessagePage /> : <FourOhFour />} />

        <Route path="*" element={<FourOhFour />} />
      </Routes>
    </div>
  );
}

export default App;
