import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useCookies } from "react-cookie";
import GalleryRowService from "../services/GalleryRow.service";

type AddRowDialogProps = {
    open: boolean;
    onClose: () => void;
    categoryId: number;
}

function AddRowDialog(props: AddRowDialogProps) {

    const [cookie,,] = useCookies(["token"]);

    const handleAddRow = () => {
        const rowService: GalleryRowService = new GalleryRowService();

        rowService.addRow(props.categoryId, cookie.token).then(() => {
            props.onClose();
        })
    }

    return ( 
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Add new row</DialogTitle>
            <DialogContent>
                <Typography>Prida novej radek kam pak muzes nahazet svoje hnusny fotky mehehehe.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleAddRow}>Add Row</Button>
                <Button variant="outlined" onClick={props.onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
     );
}

export default AddRowDialog;