import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useCookies } from "react-cookie";
import GalleryRowService from "../services/GalleryRow.service";

type AddImageDialogProps = {
    open: boolean;
    onClose: () => void;
    rowId: number;
}

function AddImageDialog(props: AddImageDialogProps) {

    const [cookie,,] = useCookies(["token"]);

    const [chosenImage, setchoseImage] = useState(null);

    const handleChangeFile = (event: any) => {
        const file = event.target.files[0];
        setchoseImage(file);
      }

    const handleAddImage = () => {
        const rowService: GalleryRowService = new GalleryRowService();

        rowService.addImageToRow(props.rowId, chosenImage, cookie.token).then(() => {
            window.location.reload();
            props.onClose();
        }).catch(err => {
            console.log(err);
        })
    }

    return ( 
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Add new Image</DialogTitle>
            <DialogContent sx={{margin: 5}}>
                <Grid container p={2} spacing={2}>
                    <Grid item xs={12}>
                        <Typography>Choose picture</Typography>
                        <Button variant="contained" component="label">
                            Upload
                            <input hidden accept="image/*" onChange={(e) => handleChangeFile(e)} type="file" />
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAddImage} variant="contained">Add Picture</Button>
            </DialogActions>
        </Dialog>
        
     );
}

export default AddImageDialog;