import { Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import AppbarComponent, { AppbarLink } from "../../components/Appbar.component";
import { error, primary, secondary } from "../../constants";
import GalleryCategory from "../../models/GalleryCategory.model";
import DeleteIcon from '@mui/icons-material/Delete';
import { Add } from "@mui/icons-material";
import FooterComponent from "../../components/Footer.component";
import AddCategoryDialog from "../../dialogs/AddCategory.dialog";
import RemoveCategoryDialog from "../../dialogs/RemoveCategory.dialog";
import { useEffect, useState } from "react";
import GalleryCategoryService from "../../services/GalleryCategory.service";
import { useCookies } from "react-cookie";

const links: AppbarLink[] = [
    {
        id: 1,
        name: "Gallery",
        link: "/gallery",
        active: false,
    },
    {
        id: 2,
        name: "About Me",
        link: "/about",
        active: false,
    },
    {
        id: 3,
        name: "Contact Me",
        link: "/contact",
        active: false,
    },
    {
        id: 8,
        name: "Categories",
        link: "/admin/category",
        active: true,
    },
    {
        id: 9,
        name: "Messages",
        link: "/admin/messages",
        active: false,
    },
];

function AdminCategoryPage() {

    const [cookie,,] = useCookies(["token"]);

    const [categories, setCategories] = useState<GalleryCategory[]>([]);

    const [addCategoryDialogOpen, setAddCategoryDialogOpen] = useState(false);
    const [removeCategoryDialogOpen, setRemoveCategoryDialogOpen] = useState(false);

    const [removeChosenCategory, setRemoveChosenCategory] = useState<GalleryCategory | undefined>(undefined);

    const handleOpenAddCategoryDialog = () => {
        setAddCategoryDialogOpen(true);
    }
    const handleCloseAddCategoryDialog = () => {
        setAddCategoryDialogOpen(false);

        const categoryService: GalleryCategoryService = new GalleryCategoryService();

        categoryService.getAllCategories(cookie.token).then(res => {
            setCategories(res);
        })
    }

    const handleOpenRemoveCategoryDialog = (category: GalleryCategory) => {
        setRemoveChosenCategory(category);
        setRemoveCategoryDialogOpen(true);
    }
    const handleCloseRemoveCategoryDialog = () => {
        setRemoveCategoryDialogOpen(false);

        const categoryService: GalleryCategoryService = new GalleryCategoryService();

        categoryService.getAllCategories(cookie.token).then(res => {
            setCategories(res);
        })
    }

    useEffect(() => {
        const categoryService: GalleryCategoryService = new GalleryCategoryService();

        categoryService.getAllCategories(cookie.token).then(res => {
            setCategories(res);
        })
    }, [])

    return ( 
        <Container maxWidth="lg">
            <AppbarComponent link={links} />
            <Grid container spacing={2}>
                {categories.map(category => 
                    <Grid key={category.categoryId} item xs={12} lg={6}>
                            <Paper sx={ category.image ? { background: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(https://api.jan-hajek.com/content${category.image.imagePath}) no-repeat center`, backgroundSize: "cover", height: 300 } : { height: 300 } }>
                                <Typography textAlign="right">
                                    <IconButton onClick={() => handleOpenRemoveCategoryDialog(category)}><DeleteIcon /></IconButton>
                                </Typography>
                                <Link style={{textDecoration: "none", color: primary}} to={`/admin/category/${category.categoryId}`}>
                                    <Typography textAlign="center" paddingTop={10} fontWeight="medium" fontSize={28}>
                                        { category.categoryName }
                                    </Typography>
                                    <Typography textAlign="center" variant="subtitle1" color={secondary} fontWeight="medium" fontSize={18}>
                                        { category.categoryYear }
                                    </Typography>
                                    {!category.image && <Typography textAlign="center" color={error}>
                                        INACTIVE
                                    </Typography>}
                                </Link>
                            </Paper>
                    </Grid>
                )}
                <Grid item xs={12} lg={6} textAlign="center">
                    <Paper sx={ { height: 300 } }>
                        <Tooltip title="Add a new Category">
                            <IconButton sx={{marginTop: 15}} onClick={handleOpenAddCategoryDialog}>
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </Paper>
                </Grid>
            </Grid>

            <AddCategoryDialog open={addCategoryDialogOpen} onClose={handleCloseAddCategoryDialog} />
            <RemoveCategoryDialog open={removeCategoryDialogOpen} onClose={handleCloseRemoveCategoryDialog} category={removeChosenCategory} />

            <FooterComponent />
        </Container>
     );
}

export default AdminCategoryPage;
