import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useCookies } from "react-cookie";
import GalleryCategory from "../models/GalleryCategory.model";
import GalleryCategoryService from "../services/GalleryCategory.service";

type RemoveCategoryDialogProps = {
    open: boolean;
    onClose: () => void;
    category?: GalleryCategory;
}

function RemoveCategoryDialog(props: RemoveCategoryDialogProps) {

    const [cookie,,] = useCookies(["token"]);

    const handleRemoveCategory = () => {
        const categoryService: GalleryCategoryService = new GalleryCategoryService();
        categoryService.deleteCategory(props.category?.categoryId!, cookie.token).then(() => {
            props.onClose();
        });
    }

    return ( 
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Remove {props.category && props.category.categoryName} category?</DialogTitle>
            <DialogContent>
                <Typography>Pokud zmacknes Remove tak ti to smaze kategorii i s fotkama tak si to poradne rozmysli curaku.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleRemoveCategory}>Remove</Button>
                <Button variant="outlined" onClick={props.onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
     );
}

export default RemoveCategoryDialog;