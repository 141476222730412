import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useCookies } from "react-cookie";
import GalleryImage from "../models/GalleryImage.model";
import GalleryCategoryService from "../services/GalleryCategory.service";

type ChangeCategoryImageProps = {
    open: boolean;
    onClose: () => void;
    image?: GalleryImage;
    categoryId: number;
}

function ChangeCategoryImage(props: ChangeCategoryImageProps) {

    const [cookie,,] = useCookies(["token"]);

    const handleRemoveImage = () => {
        const categoryService: GalleryCategoryService = new GalleryCategoryService();

        categoryService.changeCategoryPicture(props.categoryId, props.image?.imageId!, cookie.token).then(() => {
            props.onClose();
        })
    }

    return ( 
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Set this image as Category Image?</DialogTitle>
            <DialogContent>
                <Typography>Pokud zmacknes Change, tak se zmeni fotka kategorie.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleRemoveImage}>Change</Button>
                <Button variant="outlined" onClick={props.onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
     );
}

export default ChangeCategoryImage;