import { CircularProgress, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import AppbarComponent, { AppbarLink } from "../components/Appbar.component";
import CategoryComponent from "../components/Category.component";
import FooterComponent from "../components/Footer.component";
import GalleryCategory from "../models/GalleryCategory.model";
import GalleryCategoryService from "../services/GalleryCategory.service";

function GalleryPage() {

    const [cookie,,] = useCookies(["token"]);

    const [galleryCategories, setGalleryCategories] = useState<GalleryCategory[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    const links: AppbarLink[] = [
        {
            id: 1,
            name: "Gallery",
            link: "/gallery",
            active: true,
        },
        {
            id: 2,
            name: "About Me",
            link: "/about",
            active: false,
        },
        {
            id: 3,
            name: "Contact Me",
            link: "/contact",
            active: false,
        },
    ];

    cookie.token && links.push({id: 8, name: "Categories", link: "/admin/category", active: false});
    cookie.token && links.push({id: 9, name: "Messages", link: "/admin/messages", active: false});

    useEffect(() => {
        setIsLoading(true);

        const categoryService: GalleryCategoryService = new GalleryCategoryService();

        categoryService.getAllCategoriesWithImage().then(res => {
            setGalleryCategories(res);
            setIsLoading(false);
            
        }).catch(err => {
        });
    }, []);

    return ( 
        <Container maxWidth={false} sx={{ maxWidth: 1600 }}>
            <AppbarComponent link={links} />
            
            <Grid container spacing={1}>
                
                    {!isLoading && galleryCategories.map(category => 
                        <Grid key={category.categoryId} item xs={12} md={6}><CategoryComponent categoryId={category.categoryId!} categoryName={category.categoryName} categoryYear={category.categoryYear} categoryImage={category.image!.imagePath} /></Grid>)}
                    {isLoading && <Grid item xs={12} textAlign="center"><CircularProgress /></Grid>}
            </Grid>

            <FooterComponent />
        </Container>
     );
}

export default GalleryPage;