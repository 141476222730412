import { Grid, IconButton, Paper } from "@mui/material";
import { Container } from "@mui/system";
import { useParams } from "react-router-dom";
import AdminRowComponent from "../../components/admin/AdminRow.component";
import AppbarComponent, { AppbarLink } from "../../components/Appbar.component";
import FooterComponent from "../../components/Footer.component";
import AddIcon from '@mui/icons-material/Add';
import AddRowDialog from "../../dialogs/AddRow.dialog";
import { useEffect, useState } from "react";
import GalleryRowService from "../../services/GalleryRow.service";
import GalleryRow from "../../models/GalleryRow.model";

const links: AppbarLink[] = [
    {
        id: 1,
        name: "Gallery",
        link: "/gallery",
        active: false,
    },
    {
        id: 2,
        name: "About Me",
        link: "/about",
        active: false,
    },
    {
        id: 3,
        name: "Contact Me",
        link: "/contact",
        active: false,
    },
    {
        id: 8,
        name: "Categories",
        link: "/admin/category",
        active: true,
    },
    {
        id: 9,
        name: "Messages",
        link: "/admin/messages",
        active: false,
    },
];

function AdminCategoryDetailPage() {
    let { categoryId } = useParams();

    const [addRowDialogOpen, setAddRowDialogOpen] = useState(false);

    const [rows, setRows] = useState<GalleryRow[]>([]);

    const handleAddRowDialogOpen = () => {
        setAddRowDialogOpen(true);
    }
    const handleAddRowDialogClose = () => {
        setAddRowDialogOpen(false);

        const rowService: GalleryRowService = new GalleryRowService();

        rowService.getAllByCategory(Number(categoryId)).then(res => {
            setRows(res);
        });
    }

    useEffect(() => {
        const rowService: GalleryRowService = new GalleryRowService();

        rowService.getAllByCategory(Number(categoryId)).then(res => {
            setRows(res);
        })
    }, [])
    
    return ( 
        <Container maxWidth="xl">
            <AppbarComponent link={links} />

            <Grid container>
                {rows.map(row => 
                    <Grid key={row.rowId} item xs={12}>
                        <AdminRowComponent catId={Number(categoryId)} rowId={row.rowId} images={row.images} />
                    </Grid>
                )}
                <Grid item xs={12} textAlign="center">
                    <Paper sx={{height: 200, paddingTop: 10}}>
                        <IconButton onClick={handleAddRowDialogOpen}>
                            <AddIcon fontSize="large" />
                        </IconButton>
                    </Paper>
                </Grid>
            </Grid>

            <AddRowDialog open={addRowDialogOpen} onClose={handleAddRowDialogClose} categoryId={Number(categoryId)} />

            <FooterComponent />
        </Container>
     );
}

export default AdminCategoryDetailPage;