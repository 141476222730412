import axios from "axios";
import MessageModel from "../models/Message.model";

export default class MessagesService {
    private url: string = "https://api.jan-hajek.com/api/message";

    public async sendMessage(message: MessageModel): Promise<void>{
        await axios.post(`${this.url}`, message);
    }

    /* ADMIN */

    public async deleteMessage(messageId: number, token: string): Promise<void>{
        await axios.delete(`${this.url}/admin?messageId=${messageId}`, {headers: {Authorization: `Bearer ${token}`}});
    }

    public async getMessages(token: string): Promise<MessageModel[]>{
        const response = await axios.get(`${this.url}/admin`, {headers: {Authorization: `Bearer ${token}`}});

        return response.data as MessageModel[];
    }
}