import axios from "axios";
import GalleryCategory from "../models/GalleryCategory.model";

export default class GalleryCategoryService {
    private url: string = "https://api.jan-hajek.com/api/category";

    public async getAllCategoriesWithImage(): Promise<GalleryCategory[]>{
        const response = await axios.get(`${this.url}`);

        return response.data as GalleryCategory[];
    }

    public async getCategory(categoryId: number): Promise<GalleryCategory>{
        const response = await axios.get(`${this.url}/detail?categoryId=${categoryId}`);

        return response.data as GalleryCategory;
    }

    /* ADMIN */

    public async getAllCategories(token: string): Promise<GalleryCategory[]>{
        const response = await axios.get(`${this.url}/admin`, {headers: {Authorization: `Bearer ${token}`}});

        return response.data as GalleryCategory[];
    }

    public async addCategory(category: GalleryCategory, token: string): Promise<void>{
        await axios.post(`${this.url}/admin`, category, {headers: {Authorization: `Bearer ${token}`}});
    }

    public async deleteCategory(categoryId: number, token: string): Promise<void>{
        await axios.delete(`${this.url}/admin?categoryId=${categoryId}`, {headers: {Authorization: `Bearer ${token}`}});
    }

    public async changeCategoryPicture(categoryId: number, imageId: number, token: string): Promise<void>{
        await axios.put(`${this.url}/admin?categoryId=${categoryId}&imageId=${imageId}`, [], {headers: {Authorization: `Bearer ${token}`}});
    }
}