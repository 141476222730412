export const backgroundColor = "#000000";
export const primary = "#eeeeee";
export const secondary = "#9D9D9D";
export const tertiary = "#4F4F4F";
export const error ="#A63232";

export const links = {
    linkedin: "https://www.linkedin.com/in/jan-hajek-75b522248/",
    facebook: "https://www.facebook.com/hondyyy/",
    instagram: "https://www.instagram.com/hajekjann/",
    email: "mailto::xhajekjan@gmail.com",
}