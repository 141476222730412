import { Button, Grid, Link, Typography } from "@mui/material";
import { links, primary, secondary } from "../constants";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import { useState } from "react";
import { Link as LinkRouter } from "react-router-dom";
import { Container } from "@mui/system";

function HomePage() {
  const [hovered, setHovered] = useState(0);

  const style = {
    active: {
      color: secondary,
    },
    inactive: {
      color: primary,
    },
  };

  return (
    <Container>
      <Grid
        container
        direction="column"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12} mb={4} p={1} className="borderCorners" >
          <Typography variant="h1" fontWeight="medium" fontSize={70}>
            Jan
          </Typography>
          <Typography mt={-2} variant="h1" fontWeight="medium" fontSize={70}>
            Hajek
          </Typography>
          <Typography variant="subtitle1" fontSize={18} color={secondary}>
            Photographer
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Link
            style={{ marginRight: 15 }}
            href={links.linkedin}
            target="_blank"
            sx={hovered === 1 ? style.active : style.inactive}
            onMouseEnter={() => setHovered(1)}
            onMouseLeave={() => setHovered(0)}
          >
            <LinkedInIcon fontSize="medium" />
          </Link>
          <Link
            style={{ marginRight: 15 }}
            href={links.facebook}
            target="_blank"
            sx={hovered === 2 ? style.active : style.inactive}
            onMouseEnter={() => setHovered(2)}
            onMouseLeave={() => setHovered(0)}
          >
            <FacebookIcon fontSize="medium" />
          </Link>
          <Link
            style={{ marginRight: 15 }}
            href={links.instagram}
            target="_blank"
            sx={hovered === 3 ? style.active : style.inactive}
            onMouseEnter={() => setHovered(3)}
            onMouseLeave={() => setHovered(0)}
          >
            <InstagramIcon fontSize="medium" />
          </Link>
          <Link
            href={links.email}
            target="_blank"
            sx={hovered === 4 ? style.active : style.inactive}
            onMouseEnter={() => setHovered(4)}
            onMouseLeave={() => setHovered(0)}
          >
            <EmailIcon fontSize="medium" />
          </Link>

          <Typography marginTop={1}>
            <LinkRouter to="/gallery" style={{ textDecoration: "none" }}>
              <Button variant="outlined">Enter Site</Button>
            </LinkRouter>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default HomePage;
