import { Button, CircularProgress, Container, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useCookies } from "react-cookie";
import AppbarComponent, { AppbarLink } from "../components/Appbar.component";
import FooterComponent from "../components/Footer.component";
import MessageModel from "../models/Message.model";
import MessagesService from "../services/Messages.service";

function ContactPage() {

    const [cookie,,] = useCookies(["token"]);

    const links: AppbarLink[] = [
        {
            id: 1,
            name: "Gallery",
            link: "/gallery",
            active: false,
        },
        {
            id: 2,
            name: "About Me",
            link: "/about",
            active: false,
        },
        {
            id: 3,
            name: "Contact Me",
            link: "/contact",
            active: true,
        },
    ];

    cookie.token && links.push({id: 8, name: "Categories", link: "/admin/category", active: false});
    cookie.token && links.push({id: 9, name: "Messages", link: "/admin/messages", active: false});

    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [message, setMessage] = useState("");

    const [userNameErr, setUserNameErr] = useState(false);
    const [userEmailErr, setUserEmailErr] = useState(false);
    const [messageErr, setMessageErr] = useState(false);

    const [userNameErrMsg, setUserNameErrMsg] = useState("");
    const [userEmailErrMsg, setUserEmailErrMsg] = useState("");
    const [messageErrMsg, setMessageErrMsg] = useState("");

    const [messageLoading, setMessageLoading] = useState(false);
    const [messageSend, setMessageSend] = useState(false);

    const validateForm = () => {

        setUserNameErr(false);
        setUserEmailErr(false);
        setMessageErr(false);
        
        setUserNameErrMsg("");
        setUserEmailErrMsg("");
        setMessageErrMsg("");

        const emailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

        let returnValue = true;

        if(userName === ""){
            setUserNameErr(true);
            setUserNameErrMsg("Your name cannot be empty!");
            returnValue = false;
        }
        if(userEmail === ""){
            setUserEmailErr(true);
            setUserEmailErrMsg("Your name cannot be empty!");
            returnValue = false;
        }
        if(message === ""){
            setMessageErr(true);
            setMessageErrMsg("Your name cannot be empty!");
            returnValue = false;
        }
        if(!userEmail.trim().match(emailRegex)){
            setUserEmailErr(true);
            setUserEmailErrMsg("Email is in wrong format!");
            returnValue = false;
        }

        return returnValue;
    };
    
    const sendMessage = () => {
        if(validateForm()){
            setMessageLoading(true);

            const messageService: MessagesService = new MessagesService();

            const newMessage: MessageModel = new MessageModel(userName, userEmail, message);

            messageService.sendMessage(newMessage).then(() => {
                setMessageLoading(false);
                setMessageSend(true);
            })
        }
    };

    return ( 
        <Container maxWidth="xs">
            <AppbarComponent link={links} />

            
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField label="Your Name" required fullWidth error={userNameErr} helperText={userNameErrMsg} onChange={(e) => setUserName(e.target.value) } />
                </Grid>
                <Grid item xs={12}>
                    <TextField label="Your Email" required fullWidth error={userEmailErr} helperText={userEmailErrMsg} onChange={(e) => setUserEmail(e.target.value) } />
                </Grid>
                <Grid item xs={12}>
                    <TextField label="Message" rows={5} required multiline fullWidth error={messageErr} helperText={messageErrMsg} onChange={(e) => setMessage(e.target.value) } />
                </Grid>
                <Grid item xs={12} textAlign="center">
                    {!messageSend && messageLoading && <Button disabled variant="outlined"><CircularProgress size={18} sx={{marginRight: 2}} /> Sending message...</Button>}
                    {!messageSend && !messageLoading && <Button onClick={sendMessage} variant="outlined">Send message</Button>}
                    {messageSend && <Button variant="outlined" disabled>Message Sent</Button>}
                </Grid>
            </Grid>


            <FooterComponent />
        </Container>
     );
}

export default ContactPage;