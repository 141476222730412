import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useCookies } from "react-cookie";
import GalleryCategoryService from "../services/GalleryCategory.service";

type AddCategoryDialogProps = {
    open: boolean;
    onClose: () => void;
}

function AddCategoryDialog(props: AddCategoryDialogProps) {

    const [cookie,,] = useCookies(["token"]);

    const [categoryName, setCategoryName] = useState("");
    const [categoryYear, setCategoryYear] = useState(new Date().getFullYear());

    const [categoryNameErr, setCategoryNameErr] = useState(false);

    const validate = () => {
        setCategoryNameErr(false);
        if(categoryName === "")
        {
            setCategoryNameErr(true);
            return false;
        }
        return true;
    }

    const handleAddCategory = () => {

        if(validate()){
            const categoryService: GalleryCategoryService = new GalleryCategoryService();
            categoryService.addCategory({categoryName: categoryName, categoryYear: categoryYear},cookie.token).then(res => {
                props.onClose();
        })
        }
    }

    return ( 
        <Dialog open={props.open} onClose={props.onClose} >
            <DialogTitle>Add new Category</DialogTitle>
            <DialogContent>
                <Grid container p={2} spacing={2}>
                    <Grid item xs={12}>
                        <TextField fullWidth label="Category Name" error={categoryNameErr} helperText="Cannot be empty" onChange={(e) => setCategoryName(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}  textAlign="center">
                        <TextField type="number" defaultValue={new Date().getFullYear()} onChange={(e) => setCategoryYear(Number(e.target.value))} label="Category Year" />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Button onClick={handleAddCategory} variant="contained">Add new Category</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
     );
}

export default AddCategoryDialog;