import { Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { secondary } from "../constants";

type CategoryComponentProps = {
    categoryId: number;
    categoryName: string;
    categoryYear: number;
    categoryImage: string;
}

function CategoryComponent(props: CategoryComponentProps) {

    const [isHovered, setIsHovered] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

    const [categoryImage, extension] = props.categoryImage.split(".");

    useEffect(() => {
      window.addEventListener('resize', () => setIsMobile(window.innerWidth < 960), { passive: true });
  
      return () => {
          window.removeEventListener('resize', () => setIsMobile(window.innerWidth < 960));
      };
    }, []);

    const style = {
        inactive: {
            height: { xs: 225, sm: 330, md: 450, lg: 560},
            background: `url(https://api.jan-hajek.com/content${categoryImage}_thumbnail.${extension}) no-repeat center`,
            backgroundSize: "cover",
            borderRadius: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          },
          active: {
            height: { xs: 225, sm: 330, md: 450, lg: 560},
            background: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(https://api.jan-hajek.com/content${categoryImage}_thumbnail.${extension}) no-repeat center`,
            backgroundSize: "cover",
            backgroundColor: "#000000",
            borderRadius: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          },
    }

    return ( 
       <Link to={ `/gallery/${props.categoryId}` } style={{textDecoration: "none"}} >
            <Paper onMouseEnter={()=> setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} elevation={0} sx={(isHovered || isMobile) ? style.active : style.inactive}>
                {(isHovered || isMobile) && <Typography textAlign="center" variant="h2" fontSize="26px" fontWeight="medium">
                        {props.categoryName}
                </Typography>}
                {(isHovered || isMobile) && <Typography textAlign="center" color={secondary} variant="subtitle1" fontSize="18" fontWeight="light">
                        {props.categoryYear}
                </Typography>}
            </Paper>
       </Link>
     );
}

export default CategoryComponent;
