import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import GalleryImage from "../../models/GalleryImage.model";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import AddImageDialog from "../../dialogs/AddImage.dialog";
import RemoveImageDialog from "../../dialogs/RemoveImage.dialog";
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import ChangeCategoryImage from "../../dialogs/ChangeCategoryImage.dialog";

type GalleryRowComponentProps = {
    rowId: number;
    images?: GalleryImage[];
    catId: number;
}

function AdminRowComponent(props: GalleryRowComponentProps) {

    const [addImageDialogOpen, setAddImageDialogOpen] = useState(false);
    const [removeImageDialogOpen, setRemoveImageDialogOpen] = useState(false);
    const [changeImageDialogOpen, setChangeImageDialogOpen] = useState(false);

    const [imageToRemove, setImageToRemove] = useState<GalleryImage | undefined>(undefined);

    const handleAddImageDialogOpen = () => {
        setAddImageDialogOpen(true);
    }
    const handleAddImageDialogClose = () => {
        setAddImageDialogOpen(false);
    }

    const handleRemoveImageDialogOpen = (image: GalleryImage) => {
        setImageToRemove(image);
        setRemoveImageDialogOpen(true);
    }
    const handleRemoveImageDialogClose = () => {
        setRemoveImageDialogOpen(false);
    }

    const handleChangeImageOpen = (image: GalleryImage) => {
        setImageToRemove(image);
        setChangeImageDialogOpen(true);
    }

    const handleChangeImageClose = () => {
        setChangeImageDialogOpen(false);
    }

    return ( 
        <Grid container>
            {props.images && props.images?.map(image => 
                <Grid key={image.imageId} item xs={props.images!.length < 5 ? 12/(props.images!.length+1) : 12/props.images!.length} sx={{
                    background: `url(https://api.jan-hajek.com/content${image.imagePath}) no-repeat center`,
                    backgroundSize: "cover", 
                    height: { xs: 225, sm: 330, md: 450, lg: 560},}}
                    textAlign="left"
                    >
                        <Grid container>
                            <Grid item xs={6} textAlign="left">
                                <Tooltip title="Set as Category image">
                                    <IconButton onClick={() => handleChangeImageOpen(image)}>
                                        <PhotoSizeSelectActualIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                                <IconButton onClick={() => handleRemoveImageDialogOpen(image)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                            
                </Grid>
            )}
            {props.images && props.images!.length < 5 && 
                <Grid item xs={12/(props.images!.length+1)} sx={{backgroundColor: "#121212", height: { xs: 225, sm: 330, md: 450, lg: 560}, paddingTop: {xs: 5, lg: 30}}} textAlign="center">
                    <IconButton onClick={handleAddImageDialogOpen}>
                        <AddAPhotoIcon fontSize="large" />
                    </IconButton>
                </Grid>
            }
            {!props.images && 
            <Grid item xs={12} sx={{backgroundColor: "#121212",height: { xs: 225, sm: 330, md: 450, lg: 560}, paddingTop: {xs: 5, lg: 30}}} textAlign="center">
                <IconButton onClick={handleAddImageDialogOpen}>
                    <AddAPhotoIcon fontSize="large" />
                </IconButton>
            </Grid>
            }

            <AddImageDialog open={addImageDialogOpen} onClose={handleAddImageDialogClose} rowId={props.rowId} />
            <RemoveImageDialog open={removeImageDialogOpen} onClose={handleRemoveImageDialogClose} image={imageToRemove} rowId={props.rowId} />
            <ChangeCategoryImage open={changeImageDialogOpen} onClose={handleChangeImageClose} categoryId={props.catId} image={imageToRemove} />

        </Grid>
        );
}

export default AdminRowComponent;
