import { useEffect } from 'react';
import Lottie from 'lottie-web';
import Camera from '../camera.json'

function FourOhFourLottie() {
    useEffect(() => {
        const instance = Lottie.loadAnimation({
          container: document.getElementById('CameraAnimation')!,
          animationData: Camera,
          autoplay: true,
          loop: true,
        });

        return () => instance.destroy();
      }, []);
  
      return (
            <div style={{maxWidth: 400, margin: 0, position: "absolute", top: "50%", left: "50%", transform: "translateY(-50%) translateX(-50%)"}}>
                <div id="CameraAnimation">
                </div>
                <div style={{textAlign: "center", fontSize: 18}}>Page not Found. Please continue on <a style={{color: 'red'}} href="https://jan-hajek.com">Main Page</a></div>
            </div>
      );
}

export default FourOhFourLottie;