import { Container, Grid, Typography, Box, Button } from "@mui/material";
import { useCookies } from "react-cookie";
import AppbarComponent, { AppbarLink } from "../components/Appbar.component";
import FooterComponent from "../components/Footer.component";

function AboutPage() {

    const [cookie,,] = useCookies(["token"]);

    const links: AppbarLink[] = [
        {
            id: 1,
            name: "Gallery",
            link: "/gallery",
            active: false,
        },
        {
            id: 2,
            name: "About Me",
            link: "/about",
            active: true,
        },
        {
            id: 3,
            name: "Contact Me",
            link: "/contact",
            active: false,
        },
    ];

    cookie.token && links.push({id: 8, name: "Categories", link: "/admin/category", active: false});
    cookie.token && links.push({id: 9, name: "Messages", link: "/admin/messages", active: false});

    return ( 
        <Container>
            <AppbarComponent link={links} />
            <Grid container>
                <Grid item md={7}>
                    <Typography variant="h3" pb={2}>
                    Hello, I am Jan!
                    </Typography>
                    <Typography pb={4}>
                        I live in Horsens, Denmark. As an professional photographer with the love for outdoors and social events, I will help you make the memories tangible. Family occasions, individual portraits, sales of real estate properties, or various expositions - you name it and I will show up with my camera. In pursuit of my artistic career, I have discovered a variety of beautiful locations, so I can match a perfect one for your photoshoot. 
                        My passion for photography started already when I was a boy, following my dad to dog shows and joining him for photoshoots. 
                    </Typography>
                
                    <Typography variant="h4" pb={2}>
                        Process of work 
                    </Typography>
                    <Box>
                        <Typography variant="h6">
                            1. Initial contact with a customer (asking for desired results) 
                        </Typography>
                        <Typography pb={2}>
                            First of all, I will call you to schedule our first meeting. We will talk about the location and process of your event, your expectations of the photos and my suggestions for the photoshooting. 
                        </Typography>

                        <Typography variant="h6">
                            2. Photoshooting 
                        </Typography>
                        <Typography pb={2}>
                        During the photoshooting, you don’t have to worry about a thing. Your responsibility is to enjoy yourself. My responsibility is to make your memories last forever. 
                        </Typography>

                        <Typography variant="h6">
                            3. Photo editing
                        </Typography>
                        <Typography pb={2}>
                        Right after the photoshoot, I will jump right to the editing part. I will have to go through all photos, and edit the visual components, so that the photos look as lively as possible. 
                        </Typography>

                        <Typography variant="h6">
                            4. Final delivery 
                        </Typography>
                        <Typography pb={4}>
                            There we have it! Photos are chosen, edited, and delivered to your digital mailbox. From now on, you have a tangible memory of that event for the rest of your life. 
                            Do you have an event planned in the near future? Contact me, and I will help you remember it!
                        </Typography>
                        <Box textAlign="center">
                            <Button href="/contact" variant="outlined">Contact Me</Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={5} sx={{opacity: "90%"}}>
                    <img src="/media/images/profile.jpg" alt="Profile" style={{maxWidth: "100%"}} />
                </Grid>
                <Grid item xs={12} textAlign="center" mt={10}>
                    <Typography variant="h5">PARTNERS THAT I AM WORKING WITH:</Typography>
                    <Box mt={1} sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <a href="https://hajekphotography.cz/" target="_blank" rel="noreferrer">
                            <img alt="Hajek Photography" src="https://hajekphotography.cz/wp-content/themes/design/img/logo.png" height={40} />
                        </a>
                        <a href="https://horsensmuaythai.dk/" target="_blank" rel="noreferrer">
                            <img alt="Horsens Muay Thai" src="/media/images/mtlogo.png" height={40} />
                        </a>
                    </Box>
                </Grid>
            </Grid>
            <FooterComponent />
        </Container>
     );
}

export default AboutPage;