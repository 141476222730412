import { AppBar, Box, Button, Container, Divider, Drawer, Grid, IconButton, Link, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { Link as LinkRouter } from "react-router-dom";
import { links, primary, secondary } from "../constants";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';

const drawerWidth = 240;

export interface AppbarLink{
    id: number;
    name: string;
    link: string;
    active: boolean;
}

const style = {
    active: {
        color: primary
    },
    inactive: {
        color: secondary
    }
};

type AppbarComponentProps = {
    link: AppbarLink[];
};

function AppbarComponent(props: AppbarComponentProps) {
  const [mobileOpen, setMobileOpen] = useState(false);

  const [hovered, setHovered] = useState(0);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Jan&nbsp;<span style={{color: "#ff0000eb"}}>Hajek</span>
      </Typography>
      <Divider />
      <List>
        {props.link.map((item) => (
            <LinkRouter key={item.id} to={item.link} style={{ textDecoration: "none" }}>
                <ListItem disablePadding>
                    <ListItemButton sx={{ textAlign: 'center', color: item.active ? primary : secondary }}>
                    <ListItemText primary={item.name} />
                    </ListItemButton>
                </ListItem>
            </LinkRouter>
        ))}
      </List>
    </Box>
  );
  
    return ( 
        <Box sx={{ display: 'flex', marginBottom: { md: 15, xs: 10 } }}>
      <AppBar component="nav" sx={{backgroundColor: "#000000", backgroundImage: "none"}}>
      <Container maxWidth={false} sx={{ maxWidth: 1600 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Grid container>
            <Grid item md={4} textAlign="left">
                <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'block' } }}>
                    {props.link.map((item) => (
                    <LinkRouter key={item.id} to={item.link} style={{ textDecoration: "none" }} onMouseEnter={() => setHovered(item.id)} onMouseLeave={() => setHovered(0)}>
                        <Button sx={item.active ? style.active : hovered === item.id ? style.active : style.inactive}>
                            {item.name}
                        </Button>
                    </LinkRouter>
                    ))}
                </Box>
            </Grid>
            <Grid item md={4} textAlign="center">
                <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'none', lg: 'block' } }}
                >
                    <LinkRouter to="/" style={{ textDecoration: "none", color: primary }}>
                        <Button sx={{fontSize: 24}}>Jan&nbsp;<span style={{color: "#ff0000eb"}}>Hajek</span></Button>
                    </LinkRouter>
                </Typography>
            </Grid>
            <Grid item md={4} textAlign="right">
                <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <Link href={links.linkedin} target="_blank" marginRight={1} onMouseEnter={() => setHovered(5)} onMouseLeave={() => setHovered(0)}>
                        <Button style={hovered === 5 ? style.active : style.inactive} sx={{ minHeight: 0, minWidth: 0, padding: 0 }}><LinkedInIcon /></Button>
                    </Link>
                    <Link href={links.facebook} target="_blank" marginRight={1} onMouseEnter={() => setHovered(6)} onMouseLeave={() => setHovered(0)}>
                        <Button style={hovered === 6 ? style.active : style.inactive} sx={{ minHeight: 0, minWidth: 0, padding: 0 }}><FacebookIcon /></Button>
                    </Link>
                    <Link href={links.instagram} target="_blank" marginRight={1} onMouseEnter={() => setHovered(7)} onMouseLeave={() => setHovered(0)}>
                        <Button style={hovered === 7 ? style.active : style.inactive} sx={{ minHeight: 0, minWidth: 0, padding: 0 }}><InstagramIcon /></Button>
                    </Link>
                    <Link href={links.email} target="_blank" marginRight={1} onMouseEnter={() => setHovered(8)} onMouseLeave={() => setHovered(0)}>
                        <Button style={hovered === 8 ? style.active : style.inactive} sx={{ minHeight: 0, minWidth: 0, padding: 0 }}><EmailIcon /></Button>
                    </Link>
                </Box>
            </Grid>
          </Grid>

        </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      </Box>
     );
}

export default AppbarComponent;