import { Button, Container, Grid, Link, Typography } from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import { links, primary, secondary } from "../constants";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useCookies } from "react-cookie";

function FooterComponent() {

    const [hovered, setHovered] = useState(0);

    const [cookie, , removeCookie] = useCookies(['token']);

    const style = {
        active: {
            color: primary
        },
        inactive: {
            color: secondary
        }
    };

    return ( 
        <Container maxWidth="xs" sx={{marginTop: 5, marginBottom: 5}}>
            <Grid container>
                <Grid item xs={12} textAlign="center">
                    <Link style={{ marginRight: 4 }} href={links.linkedin} target="_blank" sx={hovered === 1 ? style.active : style.inactive} onMouseEnter={() => setHovered(1)} onMouseLeave={() => setHovered(0)}>
                        <LinkedInIcon />
                    </Link>
                    <Link style={{ marginRight: 4 }} href={links.facebook} target="_blank" sx={hovered === 2 ? style.active : style.inactive} onMouseEnter={() => setHovered(2)} onMouseLeave={() => setHovered(0)}>
                        <FacebookIcon />
                    </Link>
                    <Link style={{ marginRight: 4 }} href={links.instagram} target="_blank" sx={hovered === 3 ? style.active : style.inactive} onMouseEnter={() => setHovered(3)} onMouseLeave={() => setHovered(0)}>
                        <InstagramIcon />
                    </Link>
                    <Link href={links.email} target="_blank" sx={hovered === 4 ? style.active : style.inactive} onMouseEnter={() => setHovered(4)} onMouseLeave={() => setHovered(0)}>
                        <EmailIcon />
                    </Link>
                </Grid>
            </Grid>
            <Grid item textAlign="center">
                <Typography sx={style.inactive}>Jan Hajek&nbsp;
                    { !cookie.token && <RouterLink to="/login" style={{textDecoration: "none", color: secondary}}>&copy;</RouterLink> }
                    { cookie.token && <Button onClick={() => removeCookie("token")} style={{textDecoration: "none", minWidth: 0, color: secondary}}>&copy;</Button> }
                    &nbsp;{ new Date().getFullYear() }
                </Typography>
            </Grid>
        </Container>
     );
}

export default FooterComponent;