import axios from "axios";

export default class UserService{
    private url: string = "https://api.jan-hajek.com/api/auth";

    public async loginUser(username: string, password: string): Promise<string | null>{
        const params = new URLSearchParams();
        params.append("username", username);
        params.append("password", password);

        const response = await axios.post(`${this.url}/login`, params);
        
        return response.data.access_token;
    }
}