import axios from "axios";
import GalleryRow from "../models/GalleryRow.model";

export default class GalleryRowService {
    private url: string = "https://api.jan-hajek.com/api/image";

    public async getAllByCategory(categoryId: number): Promise<GalleryRow[]>{
        const response = await axios.get(`${this.url}?categoryId=${categoryId}`);

        return response.data as GalleryRow[];
    }

    /* ADMIN */

    public async addImageToRow(rowId: number, file: any, token: string): Promise<void>{
        let formData = new FormData();
        formData.append("rowId", String(rowId));
        formData.append("image", file);

        await axios.post(`${this.url}/admin/image`, formData, {headers: {Authorization: `Bearer ${token}`}});
    }

    public async addRow(categoryId: number, token: string): Promise<void>{
        await axios.post(`${this.url}/admin/row?categoryId=${categoryId}`, [], {headers: {Authorization: `Bearer ${token}`}});
    }

    public async deleteImage(rowId: number, imageId: number, token: string): Promise<void>{
        await axios.delete(`${this.url}/admin/image?rowId=${rowId}&imageId=${imageId}`, {headers: {Authorization:`Bearer ${token}`}});
    }

    public async deleteRow(rowId: number, token: string): Promise<void>{
        await axios.delete(`${this.url}/admin/image?rowId=${rowId}`, {headers: {Authorization:`Bearer ${token}`}});
    }
}