import { Alert, Button, CircularProgress, Container, Grid, Snackbar, TextField } from "@mui/material";
import { useState } from "react";
import { useCookies } from "react-cookie";
import AppbarComponent, { AppbarLink } from "../components/Appbar.component";
import FooterComponent from "../components/Footer.component";
import UserService from "../services/Users.service";

const links: AppbarLink[] = [
    {
        id: 1,
        name: "Gallery",
        link: "/gallery",
        active: false,
    },
    {
        id: 2,
        name: "About Me",
        link: "/about",
        active: false,
    },
    {
        id: 3,
        name: "Contact Me",
        link: "/contact",
        active: false,
    },
];

function LoginPage() {

    const [,setCookie,] = useCookies(["token"]);

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const [userNameErr, setUserNameErr] = useState(false);
    const [passwordErr, setPasswordErr] = useState(false);

    const [userNameErrMsg, setUserNameErrMsg] = useState("");
    const [passwordErrMsg, setPasswordErrMsg] = useState("");

    const [loggingIn, setLoggingIn] = useState(false);

    const [errorLoggingIn, setErrorLoggingIn] = useState(false);

    const closeSnackbar = () => {
        setErrorLoggingIn(false);
    }

    const validateForm = () => {

        setUserNameErr(false);
        setPasswordErr(false);
        
        setUserNameErrMsg("");
        setPasswordErrMsg("");

        let returnValue = true;

        if(userName === ""){
            setUserNameErr(true);
            setUserNameErrMsg("Username cannot be empty!");
            returnValue = false;
        }
        if(password === ""){
            setPasswordErr(true);
            setPasswordErrMsg("Password cannot be empty!");
            returnValue = false;
        }

        return returnValue;
    };
    
    const sendMessage = () => {
        if(validateForm()){
            setLoggingIn(true);

            const userService: UserService = new UserService();
            userService.loginUser(userName, password).then(res => {
                setLoggingIn(false);
                setCookie("token", res);
                window.location.href = "/gallery";
            }).catch(() => {
                setLoggingIn(false);
                setErrorLoggingIn(true);
            })
        }
    };

    return ( 
        <Container maxWidth="xs">
            <AppbarComponent link={links} />

            
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField label="Your Name" required fullWidth error={userNameErr} helperText={userNameErrMsg} onChange={(e) => setUserName(e.target.value) } />
                </Grid>
                <Grid item xs={12}>
                    <TextField type="password" label="Password" required fullWidth error={passwordErr} helperText={passwordErrMsg} onChange={(e) => setPassword(e.target.value) } />
                </Grid>
                <Grid item xs={12} textAlign="center">
                    {loggingIn && <Button disabled variant="outlined"><CircularProgress size={18} sx={{marginRight: 2}} /> Logging in...</Button>}
                    {!loggingIn && <Button onClick={sendMessage} variant="contained">Login</Button>}
                </Grid>
            </Grid>

            <Snackbar open={errorLoggingIn} autoHideDuration={6000} onClose={closeSnackbar} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                <Alert onClose={closeSnackbar} variant="outlined" severity="error" sx={{ width: '100%' }}>
                    Wrong password or username.
                </Alert>
            </Snackbar>

            <FooterComponent />
        </Container>
     );
}

export default LoginPage;