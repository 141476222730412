import { Container } from "@mui/material";
import FourOhFourLottie from "../components/FourOhFour.component";

function FourOhFour() {

  return (
    <Container>
        <FourOhFourLottie />
    </Container>
  );
}

export default FourOhFour;
