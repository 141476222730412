import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useCookies } from "react-cookie";
import MessageModel from "../models/Message.model";
import MessagesService from "../services/Messages.service";

type RemoveMessageDialogProps = {
    open: boolean;
    onClose: () => void;
    message?: MessageModel;
}

function RemoveMessageDialog(props: RemoveMessageDialogProps) {

    const [cookie,,] = useCookies(["token"]);

    const handleRemoveMessage = () => {
        const messageService: MessagesService = new MessagesService();
        
        messageService.deleteMessage(props.message?.messageId!, cookie.token).then(() => {
            props.onClose();
        });
    }

    return ( 
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Remove message from { props.message && props.message.userName }?</DialogTitle>
            <DialogContent>
                <Typography>Pokud zmacknes Remove tak to smaze zpravu.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleRemoveMessage}>Remove</Button>
                <Button variant="outlined" onClick={props.onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
     );
}

export default RemoveMessageDialog;