export default class MessageModel {
    public messageId?: number;
    public userName: string;
    public userEmail: string;
    public messageText: string;

    constructor(userName: string, userEmail: string, messageText: string, messageId?: number)
    {
        this.messageId = messageId;
        this.userName = userName;
        this.userEmail = userEmail;
        this.messageText = messageText;
    }
}